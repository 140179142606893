import { createMuiTheme } from '@material-ui/core/styles'

// NOTE check this https://material-ui.com/customization/default-theme/

const defaultTheme = createMuiTheme()
const { breakpoints } = defaultTheme

const modernFont = '-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif'
const specialFont = `Inter,${modernFont}`

const theme = createMuiTheme({
  mixins: {
    contactBanner: {
      minHeight: 50,
    },
    toolbar: {
      minHeight: 80,
    },
  },
  palette: {
    primary: { main: '#09121E', gradient: 'linear-gradient(0deg, #09121E,  #2E3949)', light: '#20416B' },
    secondary: { main: '#EDDCC6', intense: '#E0A96D' },
  },
  typography: {
    color: '#222222',
    fontFamily: specialFont,
    modernFont,
    specialFont,
    link: {
      textDecoration: 'none',
      fontFamily: specialFont,
    },
    ctaLink: {
      fontSize: 17,
      color: '#1e3d59',
    },
  },
  centralise: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  right: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  circle: (side) => ({
    width: side,
    height: side,
    borderRadius: side / 2,
  }),
  maxMobileWidth: 400,
  overrides: {
    MuiTypography: {
      root: {
        color: '#222222',
      },
      h4: {
        fontWeight: '600',
        marginTop: 16,
        marginBottom: 16,
        [breakpoints.down('sm')]: {
          fontSize: '1.75rem',
          marginTop: 8,
          marginBottom: 8,
        },
      },
      h5: {
        fontWeight: '600',
        marginTop: 8,
        marginBottom: 8,
        [breakpoints.down('sm')]: {
          marginTop: 4,
          marginBottom: 4,
        },
      },
      h6: {
        [breakpoints.down('sm')]: {
          fontSize: 16,
        },
        // fontWeight: '600',
      },
      subtitle1: {
        marginTop: 8,
        marginBottom: 8,
        fontSize: 17,
        [breakpoints.down('sm')]: {
          marginTop: 4,
          marginBottom: 4,
          fontSize: 16,
        },
        lineHeight: 1.35,
      },
      subtitle2: {
        color: '#555',
        marginTop: 8,
        marginBottom: 8,
        fontSize: 20,
        [breakpoints.down('sm')]: {
          marginTop: 4,
          marginBottom: 4,
          fontSize: 16,
        },
        fontFamily: specialFont,
      },
    },
    MuiButton: {
      root: {
        margin: 10,
        textTransform: 'none',
      },
      contained: {
        backgroundColor: '#c00',
        '&:hover': {
          backgroundColor: '#00c',
        },
        disableRipple: true, // No more ripple, on the whole application 💣!
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: 16,
      },
      elevation1: {
        boxShadow: '0px 1px 12px 0px rgba(0,0,0,0.2)',
      },
    },
    MuiExpansionPanel: {
      rounded: {
        boxShadow: '1px 0px 40px rgba(0, 0, 0, 0.1)',
        overflow: 'hidden',
        borderRadius: 12,
        '&:first-child': {
          borderTopLeftRadius: 12,
          borderTopRightRadius: 12,
        },
        '&:last-child': {
          borderBottomLeftRadius: 12,
          borderBottomRightRadius: 12,
        },
      },
    },
    MuiInput: {
      underline: {
        '&&&&:hover:before': {
          borderBottom: '1px solid white',
        },
      },
    },
  },
})

export default { ...theme, isMobile: theme.breakpoints.down('sm') }
