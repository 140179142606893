// gatsby-browser.js
import { Theme } from '_appSetup'
import React from 'react'
import { ThemeProvider } from '@material-ui/styles'

export const wrapWithProvider = ({ element }) => {
  return (
    <ThemeProvider theme={Theme}>
      {element}
    </ThemeProvider>
  )
}

export default wrapWithProvider
