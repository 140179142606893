/* eslint-disable */
global.__DEV__ = process.env.NODE_ENV !== 'production'

const WARN_ILLEGAL_STATE_MUTATIONS = false // can have a performance impact, enable only when necessary - automatically disabled in production

const CONTACT_EMAIL = 'hello@technoquest.co.uk'
const SUPPORT_EMAIL = 'hello@technoquest.co.uk'
const CONTACT_PHONE = '+44 749 3131 500'

const TERMS_OF_USE_URL = '/documents/terms.pdf'
const PRIVACY_POLICY_URL = '/documents/privacy-policy.pdf'

const POST_CONTACT_REDIRECT_URL = 'https://calendly.com/technoquest/30min'

export default {
  WARN_ILLEGAL_STATE_MUTATIONS,
  CONTACT_EMAIL,
  SUPPORT_EMAIL,
  CONTACT_PHONE,
  TERMS_OF_USE_URL,
  PRIVACY_POLICY_URL,
  POST_CONTACT_REDIRECT_URL
}